const DEFAULT_INCLUDES = [
	{
		title: "Weekly trend reports",
		text: "Yes"
	},
	{
		title: "Access to trend database",
		text: "Yes"
	},
	{
		title: "Number of contacts for tracking",
		text: "Up to 500 contacts"
	},
	{
		title: "Keywords mention",
		text: "New Job Opening"
	},
	{
		title: "Email-based support",
		text: "Yes"
	}
];

const LIGHT_INCLUDES = [
	...DEFAULT_INCLUDES,
	{
		title: "Integrations",
		text: "None",
		disabled: true
	}
];

const START_INCLUDES = [...LIGHT_INCLUDES];
START_INCLUDES.splice(2, 1, {
	title: "Number of contacts for tracking",
	text: "From 501 to 1000"
});

const BUSINESS_INCLUDES = [
	...START_INCLUDES,
	{
		title: "Dedicated Support",
		text: "Yes"
	}
];

BUSINESS_INCLUDES.splice(2, 1, {
	title: "Number of contacts for tracking",
	text: "From 1001 to 5000"
});

const ENTERPRISE_INCLUDES = [...BUSINESS_INCLUDES];

ENTERPRISE_INCLUDES.splice(5, 1, {
	title: "Integrations",
	text: "Webhooks (Zapier, IFTTT, etc.)"
});

ENTERPRISE_INCLUDES.splice(2, 1, {
	title: "Number of contacts for tracking",
	text: "From 5001 to 20000"
});

export const PRICES = [
	{
		title: "Light",
		value: 29,
		includes: LIGHT_INCLUDES
	},
	{
		title: "Start",
		value: 49,
		includes: START_INCLUDES
	},
	{
		title: "Business",
		value: 159,
		includes: BUSINESS_INCLUDES
	},
	{
		title: "Enterprise",
		value: 249,
		includes: ENTERPRISE_INCLUDES
	}
];
