import React, { memo, useState } from "react";

import Button from "app/uikit-V2/Button";
import { IPropsButton } from "app/uikit-V2/Button/types";
import { API } from "app/api";
import { ApiUrls } from "app/api/apiUrls";
import { globalHandlerError } from "app/helper/handlerErrors";

const fetchActiveSubscription = async (success_url?: string) => {
	try {
		const response = await API.get(ApiUrls.account.onboard.subscriptionLink, {
			params: { success_url }
		});
		window.location.href = response?.data?.link;
	} catch (err) {
		globalHandlerError(err);
		throw new Error(err);
	}
};

const ActivateSubscriptionButton: React.FC<IPropsButton> = ({
	children,
	...props
}) => {
	const [loading, setLoading] = useState(false);

	const handleActivateSubscription = async () => {
		setLoading(true);
		await fetchActiveSubscription();
		setLoading(false);
	};

	return (
		<Button onClick={handleActivateSubscription} loading={loading} {...props}>
			{children}
		</Button>
	);
};

export default memo(ActivateSubscriptionButton);
