import React, { memo, useEffect, useState } from "react";

import { Container, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useIntercom } from "app/hooks/useIntercom";
import { configSelect } from "app/store/select";

import TermsAndPrivacy from "../TermsAndPrivacy";
import FirstLoginModal from "../FirstLoginModal";

import style from "./style.module.scss";
import Header from "./header";
import Footer from "./footer";

interface Props {
	header?: string;
	headerBackLink?: string;
	headerBackLinkTitle?: string;
	children?: JSX.Element;
}

const UserLayout: React.FC<Props> = ({
	header,
	headerBackLink,
	headerBackLinkTitle,
	children
}) => {
	const { is_first_login } = useSelector(configSelect.selectUser);
	const [firstLoginModalOpen, setFirstLoginModalOpen] = useState(false);

	useIntercom();

	useEffect(() => {
		if (is_first_login) {
			setFirstLoginModalOpen(true);
		}
	}, [is_first_login]);

	const onCloseFirstLoginModal = () => {
		setFirstLoginModalOpen(false);
	};

	return (
		<>
			<div className="page-main">
				<Header
					userHeader
					className={header}
					headerBackLink={headerBackLink}
					headerBackLinkTitle={headerBackLinkTitle}
				/>
				<main className="main">{children}</main>
				<Footer className="m-t--auto p-x--24">
					<Container maxWidth={false}>
						<Grid container justify="space-between" alignItems="center">
							<Grid item>
								<span className={style.text}>
									&copy; {new Date().getFullYear()} Tenoris Inc.
								</span>
							</Grid>
							<Grid item>
								<TermsAndPrivacy />
							</Grid>
						</Grid>
					</Container>
				</Footer>
			</div>

			<FirstLoginModal
				open={firstLoginModalOpen}
				onClose={onCloseFirstLoginModal}
			/>
		</>
	);
};

export default memo(UserLayout);
