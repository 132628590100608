import { useMemo } from "react";

import moment from "moment";

import Loader from "app/components/loaderV2";
import { Nullable } from "app/types";
import Typography from "app/uikit-V2/Typography";
import { getTriggerColors } from "app/helper/tracking.helper";
import config from "app/config";
import PriceLayout from "app/components/Price/PriceLayout";

import { ICompany, IPerson } from "../types";

import PersonDetailsInfo from "./components/DetailsInfo";
import styles from "./style.module.scss";
import { IconKey, IconLocation, IconPerson, IconPersonLocation } from "./icons";
import CollapseInfoItem from "./components/CollapseInfoItem";
import PersonDetailsItem from "./components/DetailsItem";
import ComingSoon from "./components/ComingSoon";
import { IInitialValues } from "./components/DetailsInfo/ContinueTrackingModal";

export const LOCATION_CHANGES_HISTORY_ID = 11;
export const POSITION_CHANGES_HISTORY_ID = 8;
export const COMPANY_CHANGES_HISTORY_ID = 9;
export const KEYWORDS_HISTORY_ID = 12;
export const VACANCY_HISTORY_ID = 14;

export const LOCATION_CHANGES_HISTORY_COLOR = getTriggerColors(
	LOCATION_CHANGES_HISTORY_ID
);
export const POSITION_CHANGES_HISTORY_COLOR = getTriggerColors(
	POSITION_CHANGES_HISTORY_ID
);
export const COMPANY_CHANGES_HISTORY_COLOR = getTriggerColors(
	COMPANY_CHANGES_HISTORY_ID
);

export const KEYWORDS_HISTORY_COLOR = getTriggerColors(KEYWORDS_HISTORY_ID);

export const VACANCY_HISTORY_COLOR = getTriggerColors(VACANCY_HISTORY_ID);

export interface PropsTriggerDetails {
	isCompany?: boolean;
	trackingId: string;
	companyId: string;
	personId?: string;
	userId?: string;
	onDelete?: () => void;
	details: Nullable<Partial<IPerson & ICompany>>;
	onSubmitSettings?: (values: IInitialValues) => void;
	title?: string;
}

const TriggerDetails: React.FC<PropsTriggerDetails> = ({
	userId,
	trackingId,
	personId,
	companyId,
	onDelete,
	details,
	title = "Prospect Details",
	isCompany
}) => {
	const {
		location_changes_history,
		position_changes_history,
		company_changes_history,
		vacancy_history_items,
		keywords_history,
		name,
		first_name,
		last_name,
		position_name,
		email,
		linked_in_url,
		events,
		is_left_company,
		website_url
		// twitter_url
	} = details || {};

	const {
		isNewLocationChangesHistory,
		isNewPositionChangesHistory,
		isNewCompanyChangesHistory,
		isNewKeywordsHistory,
		isNewVacancyHistory
	} = useMemo(() => {
		const map: Record<number, any> =
			events?.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}) || {};

		return {
			isNewLocationChangesHistory: map[LOCATION_CHANGES_HISTORY_ID],
			isNewPositionChangesHistory: map[POSITION_CHANGES_HISTORY_ID],
			isNewCompanyChangesHistory: map[COMPANY_CHANGES_HISTORY_ID],
			isNewKeywordsHistory: map[KEYWORDS_HISTORY_ID],
			isNewVacancyHistory: map[VACANCY_HISTORY_ID]
		};
	}, [events]);
	const lastUpdated = details?.last_updated_at;

	const formattedLastUpdate = lastUpdated
		? moment(lastUpdated).format("D/MM/YYYY")
		: null;

	const fullName =
		((first_name || last_name) && `${first_name || ""} ${last_name || ""}`) || "";

	const defaultName = isCompany
		? "Company name is not provided"
		: "Name is not provided";

	const currentName = !name && !fullName ? defaultName : name || fullName;

	return (
		<Loader loading={details === null} height="80vh">
			<Typography component="h2">{title}</Typography>
			<PersonDetailsInfo
				ids={{
					userId,
					trackingId,
					companyId,
					personId
				}}
				shouldRemove={is_left_company}
				linkedInUrl={linked_in_url}
				email={email}
				webSite={website_url}
				name={currentName}
				position={position_name}
				positionCurrent={position_changes_history?.[0]?.new_value}
				companyCurrent={company_changes_history?.[0]?.new_value}
				lastUpdate={formattedLastUpdate}
				onDelete={onDelete}
			/>
			<PriceLayout hideChildren>
				<div className={styles.section}>
					<Typography marginBottom="30px" component="h3">
						Triggers
					</Typography>
					{!!location_changes_history && (
						<CollapseInfoItem
							triggerNewStyle={LOCATION_CHANGES_HISTORY_COLOR}
							icon={<IconLocation />}
							isNew={isNewLocationChangesHistory}
							title="Person location change"
						>
							<PersonDetailsItem data={location_changes_history} />
						</CollapseInfoItem>
					)}
					{!!position_changes_history && (
						<CollapseInfoItem
							triggerNewStyle={POSITION_CHANGES_HISTORY_COLOR}
							icon={<IconPerson />}
							isNew={isNewPositionChangesHistory}
							title="Person position change"
						>
							<PersonDetailsItem data={position_changes_history} />
						</CollapseInfoItem>
					)}

					{!!company_changes_history && (
						<CollapseInfoItem
							triggerNewStyle={COMPANY_CHANGES_HISTORY_COLOR}
							isNew={isNewCompanyChangesHistory}
							icon={<IconPersonLocation />}
							title="Person job change"
						>
							<PersonDetailsItem data={company_changes_history} />
						</CollapseInfoItem>
					)}
					{!!keywords_history && (
						<CollapseInfoItem
							triggerNewStyle={KEYWORDS_HISTORY_COLOR}
							icon={<IconKey />}
							isNew={isNewKeywordsHistory}
							title="Keywords mention"
						>
							<PersonDetailsItem
								viewType="keywords"
								triggerStyle={KEYWORDS_HISTORY_COLOR}
								data={keywords_history}
							/>
						</CollapseInfoItem>
					)}

					{!!vacancy_history_items && (
						<CollapseInfoItem
							triggerNewStyle={VACANCY_HISTORY_COLOR}
							icon={<IconPerson />}
							isNew={isNewVacancyHistory}
							title="New Job Opening"
						>
							<PersonDetailsItem
								viewType="keywords"
								triggerStyle={VACANCY_HISTORY_COLOR}
								data={vacancy_history_items}
							/>
						</CollapseInfoItem>
					)}
				</div>
			</PriceLayout>
			<ComingSoon
				title={`${isCompany ? "Company" : "Prospect"} Insights`}
				link={config.artemLinkCalendly}
			/>
		</Loader>
	);
};

export default TriggerDetails;
