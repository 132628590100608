// @ts-nocheck
import React, { useState, useEffect } from "react";

import cl from "classnames/bind";
import { Fade, Modal as MUIModal, Backdrop } from "@material-ui/core";

import { TJustify, TSize } from "app/types";

import style from "./style.module.scss";
import Header, { HeaderModalProps } from "./components/header";
import Body from "./components/body";
import Footer from "./components/footer";

export { Header as ModalHeader, Body as ModalBody, Footer as ModalFooter };

type TBtnVariant =
	| "danger"
	| "borderless"
	| "gradient"
	| "ghost"
	| "blue"
	| "error"
	| "text"
	| "textOrange"
	| "square";

interface IFooterProps {
	firstBtnText?: string;
	secondBtnText?: string;
	secondBtnHide?: boolean;
	firstBtnHide?: boolean;
	firstBtnSize?: TSize;
	secondBtnSize?: TSize;
	secondBtnDisabled?: boolean;
	firstBtnAction?: () => void;
	secondBtnAction?: () => void;
	justify?: TJustify;
	footerContent?: React.ReactNode;
	firstBtnVariant?: TBtnVariant;
	firstBtnComponent?: React.ReactNode;
	firstBtnloading?: boolean;
	firstBtnDisabled?: boolean;
	secondBtnVariant?: TBtnVariant;
	secondBtnComponent?: React.ReactNode;
	secondBtnloading?: boolean;
	secondBtnType?: string;
}

interface IModalProps extends IFooterProps {
	open: boolean;
	onClose?: () => void;
	size?: TSize;
	showCloseIcon?: boolean;
	footer?: IFooterProps | boolean;
	[x: string]: any;
}

type Props = IModalProps & HeaderModalProps;

const Modal: React.FC<Props> & {
	Footer: React.FC<any>;
	Body: React.FC<any>;
	Header: React.FC<HeaderModalProps>;
} = ({
	open: openProps,
	iconTitle,
	onClose,
	title,
	headerContent,
	showCloseIcon,
	children,
	resetForm,
	size,
	disabled,
	className,
	classNameHeader,
	classNameFooter,
	classNameFooterInner,
	footer = false,
	error,
	footerContent,
	footerJustify,
	firstBtnText,
	firstBtnAction,
	firstBtnSize,
	firstBtnVariant,
	firstBtnHide,
	firstBtnComponent,
	firstBtnloading,
	firstBtnDisabled,
	secondBtnText,
	secondBtnAction,
	secondBtnSize,
	secondBtnVariant,
	secondBtnHide,
	secondBtnComponent,
	secondBtnloading,
	secondBtnDisabled,
	secondBtnType
}) => {
	const cx = cl.bind(style);
	const [open, setOpen] = useState(false);
	const handleClose = () => {
		if (onClose) {
			return onClose(false);
		}
		setOpen(false);
	};

	useEffect(() => {
		setOpen(openProps);
		if (openProps && resetForm) {
			resetForm();
		}
	}, [openProps, resetForm]);

	const footerProps = typeof footer === "boolean" ? {} : footer;

	return (
		<MUIModal
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			open={open}
			className={style.modal}
			disableEnforceFocus={true}
			disableRestoreFocus={true}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div>
					<div className={style.modal__outer}>
						<div
							className={cx("modal__inner", className, {
								"modal__inner--middle": size === "middle",
								"modal__inner--large": size === "large",
								"modal__inner--auto": size === "auto"
							})}
						>
							{showCloseIcon && (
								<button className={style.modal__close} onClick={handleClose}></button>
							)}
							<Header
								title={title}
								iconTitle={iconTitle}
								showCloseIcon={showCloseIcon}
								className={classNameHeader}
							>
								{headerContent}
							</Header>

							<Body className={style.modal__body}>{children}</Body>

							<Footer
								justify={footerJustify}
								onClose={handleClose}
								footer={!!footer}
								error={error}
								classNameFooter={classNameFooter}
								classNameFooterInner={classNameFooterInner}
								disabled={disabled}
								firstBtnText={firstBtnText}
								firstBtnAction={firstBtnAction || onClose}
								firstBtnSize={firstBtnSize}
								firstBtnVariant={firstBtnVariant}
								firstBtnHide={firstBtnHide}
								firstBtnComponent={firstBtnComponent}
								firstBtnloading={firstBtnloading}
								firstBtnDisabled={firstBtnDisabled}
								secondBtnText={secondBtnText}
								secondBtnAction={secondBtnAction}
								secondBtnSize={secondBtnSize}
								secondBtnVariant={secondBtnVariant}
								secondBtnHide={secondBtnHide}
								secondBtnComponent={secondBtnComponent}
								secondBtnloading={secondBtnloading}
								secondBtnDisabled={secondBtnDisabled}
								secondBtnType={secondBtnType}
								{...footerProps}
							>
								{footerContent}
							</Footer>
						</div>
					</div>
				</div>
			</Fade>
		</MUIModal>
	);
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
