import { memo, useState } from "react";

import isEqual from "react-fast-compare";

import PriceCard from "./PriceCard";
import { PRICES } from "./Price.config";
import styles from "./style.module.scss";

const Price = () => {
	const [show, setShow] = useState(false);

	const onShow = () => {
		setShow(value => !value);
	};

	return (
		<div className={styles.container}>
			{PRICES.map(item => (
				<PriceCard {...item} showDetails={show} onShow={onShow} />
			))}
		</div>
	);
};

export default memo(Price, isEqual);
